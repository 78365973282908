<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>资源管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/annotation' }">批注积累</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
			<div class="padding-b-sm">
				<el-form :model="form"  ref="form1">
					<el-form-item label="批注标题" prop="title" :rules="[{ required: true, message: '请输入批注标题' , trigger: 'blur'}]">
						<el-input style="width: 18.75rem;" v-model="form.title" placeholder="批注标题"></el-input>
						<el-button class="margin-l-sm" size="mini" type="primary" @click="addTab(currentTab)" >插入批注页</el-button>
					</el-form-item>
				</el-form>
			</div>
            <div>
                <el-tabs  v-model="currentTab" type="card" closable @tab-remove="removeTab" @tab-click="selectTab">
                    <el-tab-pane
                            v-for="item in editableTabs"
                            :key="item.name"
                            :label="item.title"
                            :name="item.name"
                    >
                    <div>
                        <el-form :model="form.pages[currentTab]" label-width="100px" ref="form" class="demo-ruleForm" v-for="(item,itemIndex) in form.pages[currentTab]" :key="itemIndex">
							<div v-for="(subItem,index) in item" :key="index">
								<el-form-item label="类型：" prop="type">
									<el-select size="mini" v-model="subItem.type" placeholder="请选择">
										<el-option
												v-for="item in type"
												:key="item.value"
												:label="item.label"
												:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item v-if="subItem.type === 1" label="内容：">
									<el-input v-model="subItem.content" type="textarea"
											  :rows="10"
											  placeholder="请输入内容"></el-input>
								</el-form-item>
								<el-form-item v-else>
										<Cover :uploadPath="uploadPath" @success="onSuccess($event,index)"></Cover>
								</el-form-item>
							</div>
                        </el-form>

                    </div>
                    </el-tab-pane>
                </el-tabs>

                <el-button class="margin-l-sm" size="mini" type="primary" @click="validateForm()" >保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
	import Cover from '../../../components/cover'
    export default {
        name: "add",
		components: { Cover },
        data() {
            return {
				uploadPath: 'annotation',
                currentTab:'0',
				type: [
					{
						value: 1,
						label: '内容'
					},
					{
						value: 2,
						label: '插图'
					}
				],
                editableTabs: [{
                    title: '批注页1',
                    name: '0',
                }],
                form:{
					title: "",
					pages: [
						{
							item: [
								{
								   content: "",
								   type: 1
								},
								{
								   content: "",
								   type: 1
								}
							]
						}
				   ]
                },
            }
        },
        methods: {
			...mapActions('annotation',['addAnnotation']),
            //添加选项
            removeOption(index){
                this.form.pages[this.currentTab].splice(index, 1)
            },
			// 表单提交前
			validateForm(){
				this.$refs['form1'].validate(async valid => {
					if (!valid) {
						return 
					} 
				});
				this.$refs.form[this.currentTab].validate(async valid => {
				  if (!valid) {
					return 
				  } 
				  this.submit()
				})
			},
            //提交表单保存
            async submit(){
               const {res_info, data } = await this.addAnnotation(this.form)
			   if(res_info !== 'ok') {
				   this.$message.error('保存失败!')
				   return
			   }
			   this.$message.success('保存成功!')
			   this.$router.push('/annotation')
            },
            selectTab(tab,event){
				this.currentTab = tab.index
            },
            //添加题目
            addTab(currentTab) {				
                let newTabName = ++currentTab;
                this.editableTabs.push({
                    title: '批注页'+(newTabName + 1),
                    name: newTabName + '',
                });
                this.form.pages.push({
					item: [
						{
							content: "",
							type: 1
						},
						{
							content: "",
							type: 1
						}
					]
				});
                this.currentTab = newTabName + '';
				this.currentIndex = Number(this.currentTab)
            },
            //删除题目
            removeTab(targetName) {
                if(this.editableTabs.length === 1)
                {
                    this.$message.error('至少保存一页标注')
                    return
                }
                let tabs = this.editableTabs;
                let activeName = this.currentTab;
                if (activeName === targetName) {
					var vm = this
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.name;
                            }
                        }
                    });
                    vm.form.pages.splice(targetName, 1)
                }
                this.currentTab = activeName;
                this.editableTabs = tabs.filter(tab => tab.name !== targetName);
            },
			// 图片上传
			onSuccess(path,i) {
				var that = this
				let content = that.form.pages[this.currentTab].item
				content.forEach(function(item,index){
					if(item.type === 2){
						if(index === i){
							item.content = path
						}
					}
				});
			},
			// 图片移除
			onRemove(index,file){
				this.form.pages[this.currentTab].splice(index,1)
			}
        }
    }
</script>
